import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoProps, PublicationProps } from '../../types/pages'
import { SectionBase, SectionLogoList, SectionPush } from '../../types/sections'
import { IUseCaseCard } from '../../types/resources'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../utils/constants'
import Section from '../../components/sections'
import { ResourcesFilters, ResourcesHeroWithSearch, ResourcesResult } from '../../components/resources/commons'
import { ResourceContextProvider } from '../../contexts/ResourceContext'

interface UseCasesQuery {
  landing: {
    meta: PublicationProps
    pageInfo: PageInfoProps
    preHeadline: string
    headline: string
    subHeadline: string
    searchPlaceholder: string
    filterTagLabel: string
    filterAllLabel: string
    filterToolLabel: string
    featuredLabel: string
    featuredUseCases: Array<IUseCaseCard>
    sections: Array<SectionPush | SectionLogoList>
  }
  useCases: {
    nodes: Array<IUseCaseCard>
  }
  tools: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
  tags: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
}

const UseCases: React.FC<PageProps<UseCasesQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { landing, useCases, tags, tools } = data

  return (
    <Layout
      pageId={'id'}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={landing.pageInfo.hidePrefooter}
    >
      <Seo
        slug={landing.pageInfo.slug}
        canonicalUrl={landing.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={landing.meta}
        content={landing.pageInfo.seo}
      />

      <ResourceContextProvider items={useCases.nodes}>
        <ResourcesHeroWithSearch
          preHeadline={landing.preHeadline}
          headline={landing.headline}
          subHeadline={landing.subHeadline}
          searchPlaceholder={landing.searchPlaceholder}
        />

        <Box my={SECTION_MARGIN}>
          <Grid>
            <Row>
              <Col xs={12} md={3}>
                <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                  <ResourcesFilters
                    filters={[
                      {
                        id: 'tag',
                        label: landing.filterTagLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          ...tags.nodes.map((t) => ({ label: t.name, value: t.id })),
                        ],
                      },
                      {
                        id: 'tool',
                        label: landing.filterToolLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          ...tools.nodes.map((t) => ({ label: t.name, value: t.id })),
                        ],
                      },
                    ]}
                  />
                </Box>
              </Col>
              <Col xs={12} md={9}>
                <ResourcesResult
                  itemsPerRow={2}
                  featuredLabel={landing.featuredLabel}
                  featured={landing.featuredUseCases}
                />
              </Col>
            </Row>
          </Grid>
        </Box>

        {landing.sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
      </ResourceContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query UseCasesQuery($locale: String!, $useCasesTags: [String!]!, $useCasesTools: [String!]!) {
    landing: datoCmsResourcesGlobal(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }

      pageInfo: useCasesPageInfo {
        ...pageInfoFragment
      }
      preHeadline: useCasesPreHeadline
      headline: useCasesHeadline
      subHeadline: useCasesSubHeadline
      searchPlaceholder: useCasesSearchPlaceholder
      filterAllLabel
      filterTagLabel: useCasesFilterTagLabel
      filterToolLabel: useCasesFilterToolLabel
      featuredLabel
      featuredUseCases {
        ...useCaseCardFragment
      }
      sections: useCasesSections {
        ...sectionPushFragment
        ...sectionLogoListFragment
      }
    }
    tags: allDatoCmsResourceTag(
      locale: $locale
      filter: { id: { in: $useCasesTags } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
      }
    }
    tools: allDatoCmsIntegration(
      locale: $locale
      filter: { id: { in: $useCasesTools } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
      }
    }
    useCases: allDatoCmsUseCase(locale: $locale) {
      nodes {
        ...useCaseCardFragment
      }
    }
  }
`

export default UseCases
